import announcements from './modules/announcements'
import auth from './modules/auth'
import contacts from './modules/contacts'
import designs from './modules/designs'
import metrics from './modules/metrics'
import passes from './modules/passes'
import projects from './modules/projects'
import user from './modules/user'

export default {
    announcements,
    auth,
    contacts,
    designs,
    metrics,
    passes,
    projects,
    user,
}
