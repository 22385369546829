import client from '@/api/client'

import type { Contact } from '@/types/contacts'
import type { Pagination, ListResponse } from '@/types/common/list'

export default {
    async getContacts(params?: { pagination?: Pagination; filters?: any }) {
        return client.get<ListResponse<Contact>>('/contacts', {
            params: {
                ...params?.pagination,
                ...params?.filters
            }
        })
    }
}
