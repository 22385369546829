import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PolarisVue from '@ownego/polaris-vue'
import FloatingVue from 'floating-vue'

import App from './App.vue'
import router from './router'
import { shopifyAppBridgePlugin } from './plugins/shopify'
import { i18nextPlugin } from './plugins/i18n'
import { helpersPlugin } from './plugins/helpers'
import { baseComponentsPlugin } from './plugins/base-components'
import { sentryPlugin } from './plugins/sentry'

import '@ownego/polaris-vue/dist/style.css'
import 'floating-vue/dist/style.css'
import 'vue-advanced-cropper/dist/style.css'
import '@/assets/main.scss'

const app = createApp(App)

app.use(sentryPlugin)
app.use(PolarisVue)
app.use(FloatingVue)
app.use(shopifyAppBridgePlugin)
app.use(i18nextPlugin)
app.use(helpersPlugin)
app.use(baseComponentsPlugin)
app.use(createPinia())
app.use(router)

app.mount('#app')
