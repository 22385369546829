import { defineStore } from 'pinia'
import { toast } from 'vue3-toastify'

export const useRootStore = defineStore('root', {
    state: () => ({ error: undefined, shop: undefined, init: false }),
    actions: {
        setError(error: any) {
            this.error = error

            toast(String(error), {
                autoClose: 3000,
                type: 'error'
            })
        },
        setShop(error: any) {
            this.shop = error
        }
    }
})
