import client from '@/api/client'

import type { Pass } from '@/types/passes'
import type { Pagination, ListResponse } from '@/types/common/list'

export default {
    async getPasses(params: { pagination?: Pagination; filters?: any; projectId: string }) {
        return client.get<ListResponse<Pass>>(`/wallet/projects/${params.projectId}/passes`, {
            params: {
                ...params?.pagination,
                ...params?.filters
            }
        })
    }
}
