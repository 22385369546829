import type { App } from 'vue'
import { upperFirst } from 'lodash'

import { handleError } from '@/utils/errors'

export const helpersPlugin = {
    install: (app: App) => {
        app.mixin({
            methods: {
                $c: upperFirst,
                $handleError: handleError,
            },
            computed: {
                $is_prod() {
                    return ['production', 'prod'].includes(import.meta.env.VITE_ENV)
                },
                $is_japanese() {
                    return this.$i18next.language === 'ja'
                },
            },
        })
    },
}
