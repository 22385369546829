<script>
import { defineComponent } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'

export default defineComponent({
    components: { Field, ErrorMessage },
    props: {
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: [Array, Function],
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        tooltip: String,
    },
})
</script>

<template>
    <Field v-slot="{ handleChange, value }" :rules="rules" :name="name">
        <Checkbox v-bind="$attrs" :modelValue="value" @change="handleChange">
            <template v-if="label" #label>
                <VTooltip v-if="tooltip || $slots['tooltip']">
                    <template #popper>
                        <slot v-if="$slots['tooltip']" name="tooltip" />
                        <template v-else>{{ tooltip }}</template>
                    </template>
                    <div>{{ label }}</div>
                </VTooltip>
                <div v-else>{{ label }}</div>
            </template>
            <template #helpText>
                <Text tone="critical">
                    <ErrorMessage :name="name" />
                </Text>
            </template>
        </Checkbox>
    </Field>
</template>

<style scoped>
.checkbox-container > div {
    width: max-content;
}
</style>
