import { defineStore } from 'pinia'

import API from '@/api'
import { handleError } from '@/utils/errors'

import type { DesignColors } from '@/types/designs'

export const useDesignsStore = defineStore('designs', {
    state: () => ({ colors: [] }) as { colors: DesignColors[] },
    actions: {
        setDesignsColors(designs: DesignColors[]) {
            this.colors = designs
        },
        async loadDesignsColors() {
            try {
                const colors = await API.designs.getDesignsColors()
                this.colors = colors
            } catch (error) {
                handleError(error)
            }
        },
        clearDesignsColors() {
            this.colors = []
        },
    },
})
