import { omit } from 'lodash'
import { Redirect } from '@shopify/app-bridge/actions'
import { authenticatedFetch } from '@shopify/app-bridge/utilities'

import { appBridge } from '@/plugins/shopify'
import { useRootStore } from '@/stores/root'

export class APIError extends Error {
    status: number
    response: any
    data: any

    constructor({
        response,
        data,
        defaultMessage,
    }: {
        response: any
        data?: any
        defaultMessage?: string
    }) {
        super(data.errors?.message || data.message || data || defaultMessage || 'Request failed')

        this.status = response.status
        this.response = response
        this.data = data

        Object.setPrototypeOf(this, APIError.prototype)
    }
}
/**
 * A hook that returns an auth-aware fetch function.
 * @desc The returned fetch function that matches the browser's fetch API
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * It will provide the following functionality:
 *
 * 1. Add a `X-Shopify-Access-Token` header to the request.
 * 2. Check response for `X-Shopify-API-Request-Failure-Reauthorize` header.
 * 3. Redirect the user to the reauthorization URL if the header is present.
 *
 * @returns {Function} fetch function
 */
export function useAuthenticatedFetch() {
    const fetchFunction = authenticatedFetch(appBridge)

    return async (uri: string, options?: any) => {
        const response = await fetchFunction(uri, options)

        checkHeadersForReauthorization(response.headers, appBridge)

        // const needRecallApi = await checkKinchakuAuth(response)

        // if (needRecallApi) return fetchFunction(uri, options)

        return response
    }
}

// async function checkKinchakuAuth(response: any) {
//     if (response.status === 401) {
//         const urlParams = new URLSearchParams(window.location.href)
//         const hmac = urlParams.get('hmac')
//         const host = urlParams.get('host')
//         const id_token = urlParams.get('id_token')
//         const locale = urlParams.get('locale')
//         const session = urlParams.get('session')
//         const shop = urlParams.get('shop')
//         const timestamp = urlParams.get('timestamp')

//         console.log({ hmac, host, id_token, locale, session, shop, timestamp })

//         const fetch = useAuthenticatedFetch()
//         const baseUrl = import.meta.env.VITE_BACKEND_URI ? import.meta.env.VITE_BACKEND_URI : ''

//         await fetch(baseUrl + `/shopify/callback?hmac=${hmac}&host=${host}&shop=${shop}`, {
//             method: 'GET',
//             headers: {
//                 'Cookies': `shopify_session_id=${session};`
//             }
//         })

//         return true
//     }
// }

function checkHeadersForReauthorization(headers: any, app: any) {
    if (headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1') {
        const authUrlHeader =
            headers.get('X-Shopify-API-Request-Failure-Reauthorize-Url') || '/shopify/auth'

        const redirect = Redirect.create(app)
        redirect.dispatch(
            Redirect.Action.REMOTE,
            authUrlHeader.startsWith('/')
                ? `https://${window.location.host}${authUrlHeader}`
                : authUrlHeader
        )
    }
}

class Client {
    private static instance: Client
    private fetch = useAuthenticatedFetch()
    private baseUrl = import.meta.env.VITE_BACKEND_URI
        ? import.meta.env.VITE_BACKEND_URI + '/api'
        : ''

    private mergeOptions(options: any, defaults?: any) {
        const mergedOptions = {
            ...defaults,
            ...omit(options, ['data']),
            body:
                options?.data instanceof FormData
                    ? options?.data
                    : options?.data
                      ? JSON.stringify(options.data)
                      : undefined,
        }

        if (!(options?.data instanceof FormData)) {
            mergedOptions.headers = {
                'Content-Type': 'application/json',
                ...options?.headers,
            }
        }

        return mergedOptions
    }

    private getFullUrl(path: string, options?: any) {
        const url = new URL((options?.baseUrl || this.baseUrl) + path)

        if (options?.params) {
            Object.keys(options.params).forEach(key => {
                url.searchParams.append(key, options.params[key])
            })
        }

        return url.toString()
    }

    private async getResponseData(response: any) {
        const contentType = response.headers.get('content-type')

        let responseJSON

        if (contentType?.includes('application/json')) {
            try {
                responseJSON = await response.json()
            } catch (error) {
                throw new APIError({ response, defaultMessage: 'Invalid JSON response' })
            }
        }

        if (response.status > 300) {
            throw new APIError({ response, data: responseJSON })
        }

        return responseJSON
    }

    static getInstance(): Client {
        if (!Client.instance) {
            Client.instance = new Client()
        }
        return Client.instance
    }

    async get<T>(url: string, options?: any): Promise<T> {
        const response = await this.fetch(
            this.getFullUrl(url, options),
            this.mergeOptions(options, { method: 'GET' })
        )
        return this.getResponseData(response)
    }

    async patch<T>(url: string, options?: any): Promise<T> {
        const response = await this.fetch(
            this.getFullUrl(url, options),
            this.mergeOptions(options, { method: 'PATCH' })
        )
        return this.getResponseData(response)
    }

    async post<T>(url: string, options?: any): Promise<T> {
        const response = await this.fetch(
            this.getFullUrl(url, options),
            this.mergeOptions(options, { method: 'POST' })
        )
        return this.getResponseData(response)
    }

    async put<T>(url: string, options?: any): Promise<T> {
        const response = await this.fetch(
            this.getFullUrl(url, options),
            this.mergeOptions(options, { method: 'PUT' })
        )
        return this.getResponseData(response)
    }

    async delete<T>(url: string, options?: any): Promise<T> {
        const response = await this.fetch(
            this.getFullUrl(url, options),
            this.mergeOptions(options, { method: 'DELETE' })
        )
        return this.getResponseData(response)
    }

    async request<T>(options?: any): Promise<T> {
        const response = await this.fetch(
            this.getFullUrl(options.url, options),
            this.mergeOptions(options)
        )

        return this.getResponseData(response)
    }
}

export default Client.getInstance()
