import client from '@/api/client'

import type { Announcement } from '@/types/announcement'
import type { ListResponse } from '@/types/common/list'

export default {
    async getAnnouncements(params?: { lang?: 'en' | 'ja' }) {
        return client.get<ListResponse<Announcement>>('/announcements', {
            params: {
                ...params,
                category: 'shopify'
            }
        })
    }
}
