import client from '@/api/client'

export default {
    async getMetrics(params: { type: string; range?: string }) {
        return client.get(`/metrics/${params.type}`, {
            params: {
                ...params,
                range: params.range || 'TODAY'
            }
        })
    }
}
