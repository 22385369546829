import type { App } from 'vue'
import { createApp } from '@shopify/app-bridge'

// @ts-ignore
const host = new URLSearchParams(location.search).get('host') || window.__SHOPIFY_DEV_HOST

// @ts-ignore
window.__SHOPIFY_DEV_HOST = host

export const appBridge = createApp({
  apiKey: import.meta.env.VITE_SHOPIFY_CLIENT_ID,
  host
  // forceRedirect: true
})

export const shopifyAppBridgePlugin = {
  install: (app: App) => {
    app.config.globalProperties.$appBridge = appBridge
    app.provide('useAppBridge', appBridge)
  }
}
