import type { App } from 'vue'

import KCheckBox from '@/components/_base/KCheckBox.vue'
import KColorPicker from '@/components/_base/KColorPicker.vue'
import KFileField from '@/components/_base/KFileField.vue'
import KTextField from '@/components/_base/KTextField.vue'
import KDateField from '@/components/_base/KDateField.vue'
import KSelect from '@/components/_base/KSelect.vue'

export const baseComponentsPlugin = {
  install: (app: App) => {
    app.component('KCheckBox', KCheckBox)
    app.component('KColorPicker', KColorPicker)
    app.component('KFileField', KFileField)
    app.component('KTextField', KTextField)
    app.component('KDateField', KDateField)
    app.component('KSelect', KSelect)
  }
}
