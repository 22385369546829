import { captureException } from '@sentry/browser'

import { APIError } from '@/api/client'
import { useRootStore } from '@/stores/root'

export const handleError = (error: any) => {
    if (!(error instanceof APIError)) captureException(error)

    const status = error.status
    const form = error.data?.form

    if (
        status == 422 &&
        form &&
        Object.keys(error.response?.data?.errors || {}).length > 0 &&
        typeof Object.values(error.response?.data?.errors)[0] === 'object'
    ) {
        for (const errorField in error.response?.data?.errors) {
            if (form.fields.includes(errorField)) {
                form.setFieldsErrors({
                    [errorField]: error.response?.data?.errors[errorField],
                })
            }
        }

        return
    }

    if (typeof error !== 'string') {
        if (status == 401) {
            error = 'Access Denied'
            // useAuthStore().clear()
            // useAuthStore()
            //   .login()
            //   .catch((error) => useRootStore().setError(error))
            // return
        } else {
            error = error.message || 'Request failed'
        }
    }

    useRootStore().setError(error)
}
