<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { RouterView } from 'vue-router'
import { setDefaultOptions } from 'date-fns'
import { ja, enUS } from 'date-fns/locale'
import { AppLink, NavigationMenu, Redirect, type Unsubscribe } from '@shopify/app-bridge/actions'
import 'vue3-toastify/dist/index.css'

import { useRootStore } from '@/stores/root'
import { useDesignsStore } from '@/stores/designs'
import { useUserStore } from '@/stores/user'

export default defineComponent({
    name: 'App',
    components: { RouterView },
    data() {
        return {
            redirectActionUnsubscribe: undefined as Unsubscribe | undefined,
            loading: true,
            navigationMenu: undefined as NavigationMenu.NavigationMenu | undefined,
        }
    },
    computed: {
        ...mapState(useUserStore, ['user', 'is_full_company_business_info']),
        links() {
            return [
                { label: this.$c(this.$t('dashboard')), destination: '/' },
                { label: this.$c(this.$t('loyalty program')), destination: '/projects/loyalty' },
                { label: this.$c(this.$t('members')), destination: '/members' },
                // { label: this.$c(this.$t('reports')), destination: '/reports' },
                // { label: this.$c(this.$t('utilities')), destination: '/utilities' },
                { label: this.$c(this.$t('settings')), destination: '/settings' },
            ]
        },
        app_links() {
            return this.links.map(link =>
                AppLink.create(this.$appBridge, {
                    label: link.label,
                    destination: link.destination,
                })
            )
        },
    },
    created() {
        this.checkShop()
        this.checkStaffLang()

        Promise.all([this.loadUser(), this.loadCompany()]).finally(() => {
            this.loading = false
        })
    },
    watch: {
        user() {
            if (this.user) {
                this.loadDesignsColors()
            } else {
                this.clearDesignsColors()
            }
        },
        app_links: {
            immediate: true,
            deep: true,
            handler() {
                this.renderNavigationMenu()
            },
        },
        ['$i18next.language']: {
            immediate: true,
            handler() {
                setDefaultOptions({ locale: this.$is_japanese ? ja : enUS })
            },
        },
        ['$route.path']() {
            if (!this.navigationMenu?.options?.items) return

            const items = this.navigationMenu?.options?.items
            const index = items?.findIndex(item => {
                if (item.destination === '/') return this.$route.path === '/'
                return this.$route.path.startsWith(item.destination)
            })

            if (index === -1) return

            this.navigationMenu?.set({ active: items[index] })
        },
    },
    unmounted() {
        if (this.redirectActionUnsubscribe) this.redirectActionUnsubscribe()
    },
    methods: {
        ...mapActions(useRootStore, ['setError']),
        ...mapActions(useRootStore, ['setShop']),
        ...mapActions(useUserStore, ['loadUser', 'loadCompany']),
        ...mapActions(useDesignsStore, ['loadDesignsColors']),
        ...mapActions(useDesignsStore, ['clearDesignsColors']),
        async checkStaffLang() {
            try {
                const state = await this.$appBridge.getState()
                const locale = state.staffMember.locale === 'ja-JP' ? 'ja' : 'en'
                if (this.$i18next.options.resources?.[locale]) {
                    this.$i18next.changeLanguage(locale)
                }
            } catch (error) {
                this.$handleError(error)
            }
        },
        async checkShop() {
            const params = new URLSearchParams(location.search)
            const shop = params.get('shop')?.toString()?.replace('.myshopify.com', '')

            this.setShop(shop)
        },
        renderNavigationMenu() {
            this.navigationMenu = NavigationMenu.create(this.$appBridge, {
                items: this.app_links,
            })

            this.redirectActionUnsubscribe = this.$appBridge.subscribe(
                Redirect.Action.APP,
                payload => {
                    this.$router.push(payload.path)
                }
            )
        },
    },
})
</script>

<template>
    <AppProvider style="height: 100%">
        <div v-if="loading" class="full-loader-container">
            <Box padding-block="400">
                <img src="/images/kinchaku_logo.svg" alt="KINCHAKU" style="height: 32px" />
            </Box>
            <Spinner />
        </div>
        <RouterView v-else v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </RouterView>
    </AppProvider>
</template>

<style lang="scss">
.full-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f3f4f6;
}
</style>
