import client from '@/api/client'
import type { User, Company } from '@/types/user'

export default {
    getUser() {
        return client
            .request<{ data: User }>({
                url: `/context`,
                method: 'get',
            })
            .then(response => response.data)
    },
    saveSettings(data: any) {
        return client.request<{ data: any }>({
            url: '/shopify/workspace',
            method: 'post',
            data,
        })
    },
    getCompanySettings() {
        return client
            .request<{ company: Company }>({
                url: '/user/company',
                method: 'get',
            })
            .then(response => response.company)
    },
    saveCompanySettings(data: any) {
        return client.request({
            url: '/shopify/company',
            method: 'put',
            data,
        })
    },
    startSubscription(plan: string) {
        return client
            .request<{ url: string }>({
                url: '/shopify/subscription',
                method: 'post',
                data: { plan },
            })
            .then(response => response.url)
    },
}
