import type { App } from 'vue'
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'

import enTranslation from '@/locales/en.json'
import jaTranslation from '@/locales/ja.json'

i18next.init({
    lng: 'en',
    interpolation: {
        escapeValue: false,
        // prefix: '%{',
        // suffix: '}',
    },
    fallbackLng: false,
    saveMissing: true,
    resources: {
        en: { translation: enTranslation },
        ja: { translation: jaTranslation },
    },
    missingKeyHandler: (lngs, ns, key) => {
        const lng = lngs[0]

        if (lng !== 'en') sendToPoeditor(lng, key)
    },
})

export const i18nextPlugin = {
    install: (app: App) => {
        app.use(I18NextVue, { i18next })
    },
}

const sendToPoeditor = (locale: string, key: string) => {
    const api_token = import.meta.env.VITE_POE_API_TOKEN
    const id = import.meta.env.VITE_POE_ID

    if (!api_token || !id) {
        return
    }

    const serialize = (data: any) => {
        return Object.keys(data)
            .map(function (keyName) {
                return encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
            })
            .join('&')
    }
    // test for alpha numerical to prevent jp strings from poeditor
    if (!key.match(/^[0-9a-z\s!,/.!@#$%^&*\{\}\[\]\|\(\)-_\+\"]+$/i)) {
        console.info(`[vue-i18n] skipping `, key)
        return
    }
    const ref = ''
    const data = [
        {
            term: key,
            context: '',
            reference: ref,
        },
    ]
    const url = `https://api.poeditor.com/v2/terms/add`

    fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        method: 'POST',
        mode: 'no-cors',
        body: serialize({ data: JSON.stringify(data), api_token, id }),
    })
        .then(function (response) {
            if (!response.ok) {
                console.error(response)
            }
        })
        .catch(function (e) {
            console.error(e)
        })
}
