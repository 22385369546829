<script>
import { defineComponent } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'

export default defineComponent({
    components: { Field, ErrorMessage },
    props: {
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: [Array, Function],
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        tooltip: String,
    },
})
</script>

<template>
    <Field v-slot="{ handleChange, value }" :rules="rules" :name="name">
        <TextField v-bind="$attrs" :value="value" @change="handleChange">
            <template v-if="label || $slots['label']" #label>
                <!-- TODO Refactor this. "hasUnderline" wont work because this tooltip is from Vue.
                    Instead apply CSS style="border-bottom: 1px dotted #111;" to the label if it has a tooltip -->
                <VTooltip v-if="tooltip || $slots['tooltip']">
                    <template #popper>
                        <slot v-if="$slots['tooltip']" name="tooltip" />
                        <template v-else>{{ tooltip }}</template>
                    </template>
                    <div>
                        <slot v-if="$slots['label']" name="label" />
                        <template v-else>{{ label }}</template>
                    </div>
                </VTooltip>
                <template v-else>
                    <slot v-if="$slots['label']" name="label" />
                    <template v-else>{{ label }}</template>
                </template>
            </template>
            <template v-if="$slots['helpText']" #helpText>
                <slot name="helpText" />
                <Text tone="critical">
                    <ErrorMessage :name="name" />
                </Text>
            </template>
            <template v-else #helpText>
                <Text tone="critical">
                    <ErrorMessage :name="name" />
                </Text>
            </template>

            <template v-if="$slots['connectedLeft']" #connectedLeft>
                <slot name="connectedLeft" />
            </template>

            <template v-if="$slots['connectedRight']" #connectedRight>
                <slot name="connectedRight" />
            </template>
        </TextField>
    </Field>
</template>
