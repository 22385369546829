import type { Project } from '@/types/projects'
import type { Pagination, ListResponse } from '@/types/common/list'

import client from '@/api/client'
import { toTokyoTimezone } from '@/utils/format-date'

export default {
    async getProjects(params?: { pagination?: Pagination; filters?: any }) {
        return client.get<ListResponse<Project>>('/projects/list/all', {
            params: {
                ...params?.pagination,
                ...params?.filters,
            },
        })
    },
    async getLoyaltyProjects(params?: {
        pagination?: Pagination
        filters?: any
        subtype?: 'member' | 'stamp'
    }) {
        return client.get<ListResponse<Project>>('/projects/list/loyalty', {
            params: {
                ...params?.pagination,
                ...params?.filters,
                subtype: params?.subtype,
            },
        })
    },
    async publishProject(projectId: string) {
        return client.patch(`/projects/${projectId}/publish`)
    },
    async unpublishProject(projectId: string) {
        return client.patch(`/projects/${projectId}/unpublish`)
    },
    async getProject(projectId: string) {
        return await client
            .get<{ data: Project }>(`/projects/${projectId}`)
            .then(response => response.data)
    },
    async createProject(data: any) {
        if (data.project?.start) data.project.start = toTokyoTimezone(data.project.start)
        if (data.project?.expires_at) {
            data.project.expires_at = toTokyoTimezone(data.project.expires_at)
        }

        return await client
            .post<{ data: Project }>('/projects', { data: { project: data } })
            .then(response => response.data)
    },
    async updateProject(projectId: string, data: any) {
        if (data.project?.start) data.project.start = toTokyoTimezone(data.project.start)
        if (data.project?.expires_at) {
            data.project.expires_at = toTokyoTimezone(data.project.expires_at)
        }

        return await client
            .put<{ data: Project }>(`/projects/${projectId}`, { data: { project: data } })
            .then(response => response.data)
    },
    async updateProjectImages({ project, data }: { project: Project; data: any }) {
        if (
            !Object.keys(data || {}).length ||
            (data.images &&
                Object.keys(data).length === 1 &&
                !Object.keys(data?.images || {}).length)
        ) {
            return Promise.resolve()
        }

        const formData = new FormData()

        for (const key in data) {
            if (key === 'images') {
                const encodedImages = data.images
                // const encodedImages = omitBy(data.images, (v) => {
                //   return !(typeof v == 'string' && v.indexOf('data:') == 0)
                // })

                let images = Object.keys(encodedImages).reduce(
                    (images, key) => ({ ...images, [key]: encodedImages[key] }),
                    {}
                )

                if (project.subtype === 'stamp') {
                    images = Object.assign(images, {
                        stamp_image: data.images.stamp_image,
                        reward_image: data.images.reward_image,
                    })
                }

                formData.append(
                    'project[image_states][wallpaper]',
                    typeof data.images.wallpaper !== 'string' ? 'removed' : 'present'
                )

                for (const key in images) {
                    // @ts-ignore
                    formData.append(`project[images][${key}]`, images[key])
                }
            } else {
                formData.append(`project[${key}]`, data[key])
            }
        }

        return await client
            .request<{ data: Pick<Project, 'images'> }>({
                url: `/projects/${project.id}/images`,
                method: 'post',
                data: formData,
            })
            .then(response => response.data)
    },
    async deleteProject(projectId: string) {
        return await client.delete(`/projects/${projectId}`)
    },
}
