<script>
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import { Cropper } from 'vue-advanced-cropper'
import NoteMinor from '@shopify/polaris-icons/dist/svg/NoteIcon.svg'

const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']

export default defineComponent({
    components: { Cropper },
    props: {
        modelValue: { type: [Object, File, Blob, String], default: undefined },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        allowMultiple: {
            type: Boolean,
            default: false,
        },
        hint: String,
        image: [Object, Boolean],
        tooltip: String,
    },
    setup(props) {
        const { meta, errorMessage, handleChange, handleBlur, value } = useField(
            props.name,
            props.rules,
            { initialValue: props.modelValue }
        )

        return { meta, errorMessage, handleChange, handleBlur, value }
    },
    data() {
        return {
            showCropper: false,
        }
    },
    computed: {
        files() {
            if (!this.value) return []
            if (!this.allowMultiple) return [this.value]

            return [...this.value]
        },
        file_to_copping() {
            return this.files?.length ? this.files[this.files.length - 1] : undefined
        },
    },
    watch: {
        files() {
            if (this.image && this.isImage(this.file_to_copping)) {
                this.showCropper = true
                this.croppedImage = undefined
            }
        },
    },
    methods: {
        isImage(file) {
            return validImageTypes.includes(file?.type)
        },
        isImageLink(value) {
            return typeof value === 'string' && this.image
        },
        fileSource(file) {
            return this.isImage(file) ? window.URL.createObjectURL(file) : NoteMinor
        },
        onDrop(dropFiles) {
            const value = this.allowMultiple ? dropFiles : dropFiles[0]

            this.onChange(value)
        },
        onChange(value) {
            this.handleChange(value)
            this.$emit('update:modelValue', value)
        },
        onCloseCropper() {
            this.showCropper = false
            this.onChange(undefined)
        },
        onImageCropped({ canvas }) {
            const blob = new Promise(resolve => {
                canvas.toBlob(blob => {
                    resolve(blob)
                })
            })

            blob.then(blob => {
                this.croppedImage = blob
            })
        },
        onCropperSave() {
            this.onChange(this.croppedImage)

            this.$nextTick(() => {
                this.showCropper = false
            })
        },
    },
})
</script>

<template>
    <Box>
        <div style="margin-bottom: 4px">
            <VTooltip v-if="tooltip || $slots['tooltip']">
                <template #popper>
                    <slot v-if="$slots['tooltip']" name="tooltip" />
                    <template v-else>{{ tooltip }}</template>
                </template>
                <Text>{{ label }}</Text>
            </VTooltip>
            <Text v-else>{{ label }}</Text>
        </div>
        <DropZone
            v-bind="$attrs"
            @drop="onDrop"
            :name="name"
            :allowMultiple="allowMultiple"
            class="drop-zone"
        >
            <DropZoneFileUpload
                v-if="!files?.length || showCropper"
                :actionHint="hint"
                :actionTitle="$c($t('add file'))"
            />
            <div v-else class="files-container">
                <div
                    v-for="(file, index) in files"
                    :key="index"
                    :class="{ 'files-container__item--image': isImage(file) || isImageLink(file) }"
                    class="files-container__item"
                >
                    <BlockStack v-if="!isImage(file) && !isImageLink(file)">
                        <Thumbnail size="small" :alt="file.name" :source="fileSource(file)" />
                        <div>
                            {{ file.name }}
                            <span>{{ file.size }} bytes</span>
                        </div>
                    </BlockStack>
                    <template v-else>
                        <img
                            v-for="(file, index) in files"
                            :key="index"
                            :src="isImageLink(file) ? file : fileSource(file)"
                        />
                    </template>
                </div>
            </div>
        </DropZone>
        <Text tone="critical" style="margin-top: 4px">{{ errorMessage }}</Text>
        <Modal
            :open="showCropper"
            :primary-action="{ content: $c($t('crop')), onAction: onCropperSave }"
            @close="onCloseCropper"
        >
            <template #title>{{ $c($t('crop image')) }}</template>
            <ModalSection>
                <Cropper
                    :auto-zoom="true"
                    :stencil-size="{
                        width: image?.width || undefined,
                        height: image?.height || undefined,
                    }"
                    :src="fileSource(file_to_copping)"
                    @change="onImageCropped"
                />
            </ModalSection>
        </Modal>
    </Box>
</template>

<style lang="scss" scoped>
.drop-zone::v-deep {
    .Polaris-DropZone {
        height: 120px;
    }
}
.files-container {
    overflow: hidden;
    height: 100%;

    &__item {
        &--image {
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
            border-radius: 6px;
        }
    }
}
</style>
