import { defineStore } from 'pinia'

import API from '@/api'
import { handleError } from '@/utils/errors'
import { getCurrencySymbol } from '@/utils/currency'

import type { User, Company } from '@/types/user'

export const useUserStore = defineStore('user', {
    state: () => ({ user: undefined, company: undefined } as { user?: User; company?: Company }),
    actions: {
        async loadUser() {
            try {
                this.user = await API.user.getUser()
            } catch (error) {
                handleError(error)
            }
        },
        async loadCompany() {
            try {
                this.company = await API.user.getCompanySettings()
            } catch (error) {
                handleError(error)
            }
        },
        async startSubscription(plan: string) {
            try {
                return await API.user.startSubscription(plan)
            } catch (error) {
                handleError(error)
            }
        },
        clearUser() {
            this.user = undefined
        },
    },
    getters: {
        is_jpy: state => (state.user?.currency || 'JPY').toUpperCase() === 'JPY',
        currency_symbol: state => getCurrencySymbol(state.user?.currency),
        is_subscribed: state => state.user?.is_subscribed,
        is_on_trial: state => state.user?.is_on_trial,
        trial_ended: state => state.user?.trial_ended,
        trial_ends_at: state => state.user?.trial_ends_at,
        is_full_company_business_info: state => {
            const company = state.company

            return !!(
                company?.name &&
                company?.zip &&
                company?.country &&
                company?.business_category &&
                company?.state &&
                company?.city &&
                company?.address &&
                company?.first_name &&
                company?.last_name
            )
        },
    },
})
