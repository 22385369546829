<script>
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
    props: {
        modelValue: String,
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: [Array, Function],
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        tooltip: String,
    },
    setup(props) {
        const { meta, errorMessage, handleChange, handleBlur, value } = useField(
            props.name,
            props.rules,
            {
                initialValue: String(props.modelValue).toLowerCase(),
            }
        )

        return { meta, errorMessage, handleChange, handleBlur, value }
    },
    data() {
        return {
            showPicker: true,
        }
    },
    watch: {
        modelValue(value) {
            this.handleChange(String(value).toLowerCase())
        },
    },
    methods: {
        onChange(value) {
            const hex = value.toLowerCase()
            this.$emit('update:modelValue', hex)
            this.$emit('change', hex)
            this.handleChange(hex)
        },
    },
})
</script>

<template>
    <div>
        <div style="margin-bottom: 4px">
            <VTooltip v-if="tooltip || $slots['tooltip']">
                <template #popper>
                    <slot v-if="$slots['tooltip']" name="tooltip" />
                    <template v-else>{{ tooltip }}</template>
                </template>
                <div>{{ label }}</div>
            </VTooltip>
            <Text v-else>{{ label }}</Text>
        </div>
        <ColorPicker v-if="showPicker" v-model="value" @change="onChange" output="hex" />
        <Text tone="critical" style="margin-top: 4px">
            {{ errorMessage }}
        </Text>
    </div>
</template>
