export const getCurrencySymbol = (currency: string = '') => {
    const upperCaseCurrency = currency.toUpperCase()

    switch (upperCaseCurrency) {
        case 'USD':
            return '$'
        case 'EUR':
            return '€'
        case 'GBP':
            return '£'
        case 'JPY':
            return '¥'
        default:
            return currency
    }
}
