import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

export const sentryPlugin = {
    install: (app: App) => {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: import.meta.env.VITE_ENV,
            release: import.meta.env.VITE_GIT_TAG || 'unknown',
        })
    },
}
