import { pick } from 'lodash'

import client from '@/api/client'
import type { Design, DesignColors } from '@/types/designs'
import type { ListResponse } from '@/types/common/list'

export default {
    async getDesigns() {
        return client
            .request<ListResponse<Design>>({
                url: `/designs`,
                method: 'get',
            })
            .then(response => response.data)
    },
    async getDesignsColors() {
        return client
            .request<ListResponse<DesignColors>>({
                url: `/design/colors`,
                method: 'get',
            })
            .then(response => response.data)
    },
    async createDesign(design: Pick<Design, 'name' | 'colors'>) {
        return client
            .request<{ data: Design }>({
                url: `/designs`,
                method: 'post',
                data: { design: serializeDesign(design) },
            })
            .then(response => response.data)
    },
    async updateDesign(design: Design) {
        return client
            .request<{ data: Design }>({
                url: `/designs/${design.id}`,
                method: 'put',
                data: { design: serializeDesign(design) },
            })
            .then(response => response.data)
    },
    async deleteDesign(id: string) {
        return client.request({ url: `/designs/${id}`, method: 'delete' })
    },
}

function serializeDesign(design: Pick<Design, 'name' | 'colors'>) {
    return pick(design, ['id', 'name', 'colors'])
}
