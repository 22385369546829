<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
    components: { VueDatePicker },
    props: {
        modelValue: [String, Date],
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: [Array, Function],
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        tooltip: String,
    },
    setup(props) {
        const { meta, errorMessage, handleChange, handleBlur, value } = useField(
            props.name,
            props.rules as any,
            {
                initialValue: props.modelValue,
            }
        )

        return { meta, errorMessage, handleChange, handleBlur, value }
    },
})
</script>

<template>
    <VueDatePicker v-bind="$attrs" v-model="value" @update:model-value="handleChange">
        <template #dp-input="$inputAttrs">
            <TextField v-bind="$inputAttrs">
                <template v-if="$slots['label']" #label>
                    <VTooltip v-if="tooltip || $slots['tooltip']">
                        <template #popper>
                            <slot v-if="$slots['tooltip']" name="tooltip" />
                            <template v-else>{{ tooltip }}</template>
                        </template>
                        <div><slot name="label" /></div>
                    </VTooltip>
                    <template v-else><slot name="label" /></template>
                </template>
                <template v-else-if="label" #label>
                    <VTooltip v-if="tooltip || $slots['tooltip']">
                        <template #popper>
                            <slot v-if="$slots['tooltip']" name="tooltip" />
                            <template v-else>{{ tooltip }}</template>
                        </template>
                        <div>{{ label }}</div>
                    </VTooltip>
                    <template v-else>{{ label }}</template>
                </template>
                <template v-if="$slots['helpText']" #helpText>
                    <slot name="helpText" />
                </template>
                <template v-else-if="errorMessage" #helpText>
                    <Text tone="critical">
                        {{ errorMessage }}
                    </Text>
                </template>

                <template v-if="$slots['connectedLeft']" #connectedLeft>
                    <slot name="connectedLeft" />
                </template>

                <template v-if="$slots['connectedRight']" #connectedRight>
                    <slot name="connectedRight" />
                </template>
            </TextField>
        </template>
    </VueDatePicker>
</template>
