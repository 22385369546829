import client from '@/api/client'

export default {
    async oauthRedirect(data?: any) {
        return await client.request<{ url: string }>({
            url: `/auth/shopify/redirect`,
            method: 'get',
            params: data
        })
    },
    async oauthToken(data?: any) {
        return await client.request({ url: `/auth/shopify/redirect`, method: 'get', params: data })
    }
}
