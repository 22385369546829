import { useUserStore } from '@/stores/user'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import('@/views/DashboardView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import('@/views/projects/ListView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/projects/loyalty/:id?',
            name: 'edit-loyalty-project',
            component: () => import('@/views/projects/LoyaltyEditView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/members',
            name: 'members',
            component: () => import('@/views/MembersView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import('@/views/ReportsView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/utilities',
            name: 'utilities',
            component: () => import('@/views/UtilitiesView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/settings/:tab?',
            name: 'settings',
            component: () => import('@/views/SettingsView.vue'),
            meta: { authRequired: true }
        },
        {
            path: '/unauthorized',
            name: 'unauthorized',
            component: () => import('@/views/errors/UnauthorizedView.vue')
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    // const user = useUserStore().user

    // if (to.meta.authRequired && !user) {
    //     next({ name: 'unauthorized' })
    // }

    return next()
})

export default router
